import './App.css';

//import Word from './components/Word'
import Home from './components/Home'
import "bootstrap/dist/css/bootstrap.min.css"

function App() {
  return (
    <div className="App">
      <Home />
      </div>
  );
}

export default App;
