import React, { useState, useEffect } from 'react'
import './Select.css';
import "bootstrap/dist/css/bootstrap.min.css"
import { Link, useParams } from "react-router-dom";
import $ from 'jquery';
export default function Select(props) {
  const params = useParams();
  console.log('LINE 9 params=', params.id);

  // THIS ARRAY WILL DETERMINE HOW MANY BUTTONS TO SHOW
  let buttonColors = ['primary', 'warning', 'info', 'danger', 'success', 'secondary', 'light', 'dark']
  let randNumbers = [];
  const [WordItems, setWordItems] = useState([])
  const [Completed, initCompleted] = useState(false)
  const [lastKeyNumber, setlastKeyNumber] = useState(0)
  const [CurrentWordKey, setCurrentWordKey] = useState(-1)
  const [CurrentWord, initCurrentWord] = useState({ word: "Start" })
  const [RandWords, initRandWords] = useState([])
  const [ShowWord, initShowWord] = useState(true)
  const [Score, initScore] = useState({ correct: [], wrong: [], bad: [], answeredWrong: [] })
  const [wrongCounter, initwrongCounter] = useState(0)
  const [ShowImage, initShowImage] = useState()
  const [fetching, setFetching] = useState(true)



  let playingAudio = false;
  const fetchData = async () => {
    const response = await fetch(`https://www.websor.com/pre-school/words/84words/api/?i=${params.id}&sort=${params.sort}&range=${params.range}`)
    if (!response.ok) {
      setFetching(false);
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }
  const checkAnswer = (submittedAnswer) => {
    // CORRECT ANSWER GIVEN
    if (CurrentWord.word_id === submittedAnswer) {
      //alert('correct');
      //initScore({correct: Score.correct++})
      initwrongCounter(0)
      playAudio('_answer_correct');
      initShowWord(false);
      setTimeout(() => {
        nextWord();
      }, 200);
      /// WRONG ANSWER GIVEN
    } else {
      // show each button one by one slowly because the little girl is just hitting any button until she gets it right
      
      Score.wrong.push(WordItems[CurrentWordKey]);
      if (typeof Score.answeredWrong[WordItems[CurrentWordKey].word] == "undefined") {
        Score.answeredWrong[WordItems[CurrentWordKey].word] = { count: 0, word: '' };
      }
      Score.answeredWrong[WordItems[CurrentWordKey].word].count++;
      Score.answeredWrong[WordItems[CurrentWordKey].word].word = WordItems[CurrentWordKey].word;
      Score.bad.push(WordItems[CurrentWordKey].word);
      //initwrongCounter(CurrentWordKey);
      initwrongCounter(wrongCounter + 1);
      if (wrongCounter > 3) {
        Score.bad.push(WordItems[CurrentWordKey].word);
        // Score.bad[WordItems[CurrentWordKey].word]++;
        initwrongCounter(0)
        //alert('NO CHEATING');
        $(".selectButton").hide();
        $("#SadFace").show();
        setTimeout(() => {
          $(".selectButton").show('slow');
          $("#SadFace").hide();
          //playAudio('_answer_correct');
          playAudio(WordItems[CurrentWordKey].word);
        }, 2000);
        //for (let i = 0; i < 2; i++) {
        //alert(':(')
        //setTimeout(() => {
        //playAudio('_answer_wrong');
        //$(`.btn-${buttonColors[i]}`).show('slow');
        // }, 5000); 
        //}
        // setTimeout(() => {
        //   buttonColors.each(function () {
        //     alert(this.value)
        //     setTimeout(() => {
        //       $(this).show('slow').siblings().hide("slow");;
        //     }, 1000);
        //   });
        // }, 1000);
      }
      if (params.sort == "rand") randomizeWords(CurrentWordKey)
      //Score.bad[CurrentWord.word]++
      //setCurrentWordKey(CurrentWordKey-2);
      //setTimeout(() => {
      //nextWord();
      //}, 2000);
      //setCurrentWordKey(CurrentWordKey+1);
      //nextWord();
      //CurrentWord.word[CurrentWord.word] = CurrentWord.word[CurrentWord.word] + 1;
      //Score.wrong.push(CurrentWord.word);
      //initScore({wrong: Score.wrong++})
      playAudio('_answer_wrong');
      initShowWord(true);
      //alert('wrong current id='+CurrentWord.word_id+' and you submitted '+submittedAnswer+' CurrentWordKey='+CurrentWordKey);
    }
    //console.log('LINE 24 submittedAnswer=', submittedAnswer);
    //alert(submittedAnswer);
  }
  //https://stackoverflow.com/a/12646864
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  const nextWord = () => {
    

  }
  const randomizeWords = (thisCurrentWord) => {
    // this creates a random order for the other incorrect workds are displayed.
    // Generate unique random numbers
    // https://stackoverflow.com/questions/2380019/generate-unique-random-numbers-between-1-and-100
    while (randNumbers.length < (buttonColors.length - 1)) {
      let r = Math.floor(Math.random() * (lastKeyNumber - 1)) + 1;
      if (randNumbers.indexOf(r) === -1) randNumbers.push(r);
    }
    // now push the correc answer
    randNumbers.push(CurrentWordKey);
    shuffleArray(randNumbers);
    console.log('LINE 63 randNumbers=', randNumbers);
    initRandWords(randNumbers);
  }
  const playAudio = (word) => {
    //alert(word)
    // also available: https://ssl.gstatic.com/dictionary/static/sounds/20200429/teacher--_us_1.mp3 from google
    let audio = new Audio(`https://www.websor.com/pre-school/words/84words/sounds/?w=${word}`)
    //https://stackoverflow.com/questions/16077642/javascript-html5-audio-dont-play-sound-if-already-playing
    if (!playingAudio) {
      playingAudio = true;
      setTimeout(() => {
        playingAudio = false;
        audio.play();
      }, 100);
    }
  }
  useEffect(() => {
    fetchData()
      .then((res) => {
        //console.log('LINE 15=', res);
        //console.log('LINE 15=', res.length);
        if (typeof res.error == "undefined") {
          console.log(`LINE 191 res=`, res);
          // SHUFFLE WORDS TO RANDOMIZE
          if (params.sort == "rand") res = shuffleArray(res);
          console.log(`LINE 191-B res=`, res);
          setlastKeyNumber(res.length -1);
          setCurrentWordKey(0);
          setWordItems(res);
          fetching(false); // promis completed
          res.forEach(bad => {
            console.log(`LINE 196 bad=`, bad);
            //Score.bad[bad.word] = 0;
          });
          console.log(`LINE 199 Score.bad=`, Score.bad);
          //set the default
          //nextWord()
          //alert(res.error);
        }
        else {
          // alert('error');
          initShowWord(false);
        }
      })
      .catch((e) => {
        console.log(e.message)
      })
  }, [])
  shuffleArray(buttonColors)
  return (
    <div className="container  text-center">
      output
      </div>

  )
}
