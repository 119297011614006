import React, { useState, useEffect } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link, useParams } from "react-router-dom";


export default function SlideShow(props) {
  const params = useParams();
  console.log('LINE 9 params=', params.id);

  let CurrentWordNumTemp;
  const timeoutRef = React.useRef(null);

  const [WordItems, initWord] = useState([])
  const [Completed, initCompleted] = useState(false)
  const [NumerOfWords, initNumerOfWords] = useState(0)
  const [CurrentWordNum, initCurrentWordNum] = useState(-1)
  const [CurrentWord, initCurrentWord] = useState({ word: "Start" })
  const [playingAudio, initplayingAudio] = useState(false)

  const [ShowWord, initShowWord] = useState(true)
  const [Score, initScore] = useState({ correct: [], wrong: [] })
  const [WordsStarted, initWordsStarted] = useState(false)
  const [ShowImage, initShowImage] = useState()

  const fetchData = async () => {
    const response = await fetch(`https://www.websor.com/pre-school/words/84words/api/?i=${params.id}&sort=${params.sort}&range=${params.range}`)
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }


  const nextWord = () => {

    
    if (CurrentWordNum > NumerOfWords) {
    //   console.log('LINE 19 NumerOfWords=', NumerOfWords);
    //   console.log('LINE 20 CurrentWordNum=', CurrentWordNum);
       initCompleted(true)
    } else {

      console.log(`LINE 93 nextWord() started`);
      initShowWord(true);
      CurrentWordNumTemp = CurrentWordNum;
      CurrentWordNumTemp++;
      initCurrentWordNum(CurrentWordNumTemp);
      //initCurrentWord(WordItems[CurrentWordNumTemp]);
      console.log(`LINE 77 =CurrentWordNumTemp`, CurrentWordNumTemp);
      console.log(`LINE 78 =WordItems`, WordItems);
      console.log(`LINE 79 =WordItems[]`, WordItems[CurrentWordNumTemp]);
      console.log(`LINE 80 =WordItems[]`, WordItems[CurrentWordNumTemp].word);
      initCurrentWord(WordItems[CurrentWordNumTemp]);
      setTimeout(() => {
        playAudio(WordItems[CurrentWordNumTemp].word);
      }, 1000);

        



    //   // Generate unique random numbers
    //   // https://stackoverflow.com/questions/2380019/generate-unique-random-numbers-between-1-and-100
    //   while (randNumbers.length < (buttonColors.length - 1)) {
    //     let r = Math.floor(Math.random() * (NumerOfWords - 1)) + 1;
    //     if (randNumbers.indexOf(r) === -1) randNumbers.push(r);
    //   }
    //   // now push the correc answer
    //   randNumbers.push(CurrentWordNumTemp);


    //   //console.log('LINE 63 randNumbers=', randNumbers);


    //   initRandWords(randNumbers);
    

    //   //alert();

    //   if (!WordItems[CurrentWordNumTemp].image) GetPexelImage(WordItems[CurrentWordNumTemp].word);
    }

  }
  const playAudio = (word) => {
    //alert(word)
    // also available: https://ssl.gstatic.com/dictionary/static/sounds/20200429/teacher--_us_1.mp3 from google
    let audio = new Audio(`https://www.websor.com/pre-school/words/84words/sounds/?w=${word}`)
    //https://stackoverflow.com/questions/16077642/javascript-html5-audio-dont-play-sound-if-already-playing
    if (playingAudio===false) {
      initplayingAudio(true);
      setTimeout(() => {
        initplayingAudio(false);
        audio.play();
      }, 100);
    }

  }


 

  useEffect(() => {


    fetchData()
      .then((res) => {
        //console.log('LINE 15=', res);
        //console.log('LINE 15=', res.length);

        if (typeof res.error == "undefined") {

          initNumerOfWords(res.length);
          initWord(res);
          initShowWord(true);
          
          setTimeout(() => {
            playAudio('start');
            //nextWord();
           
          }, 2500);

          //set the default
          //nextWord()
          //alert(res.error);
        }
        else {
          // alert('error');
          initShowWord(false);
        }





      })
      .catch((e) => {
        console.log(e.message)
      })
  }, [setTimeout])
 

 



  return (
    <div className="container  text-center">



      {ShowWord ?
        <div className="wordContainer">
          {CurrentWordNum === NumerOfWords ?

            <>


              <h1>Results</h1>
              <ol>
                {Score.wrong.map((wrongWord, index) => {
                  return (<li key={index}>{wrongWord}</li>)
                })}
              </ol>
            </>


            :



            <>

              <div className="card mb-4 shadow-sm">
                <div className="card-headerDisabled">


                  {ShowImage &&
                    <img className='img-fluid wordImage'
                      src={`https://www.websor.com/pre-school/words/84words/assets/img/${CurrentWord.img ? CurrentWord.img : `${CurrentWord.word}.jpg`}`}
                      alt="SlideShow"
                      title={CurrentWord.word} />
                  }



                </div>
                <div className="card-body">





                  <div className="" >
                    {/* <h1 className="text-center CurrentWord">{CurrentWord.image}</h1> */}






                    {WordsStarted ?

                      <div>
                        <div className="RepeatWord" onClick={() => playAudio(CurrentWord.word)}>🔊</div>

                        {/* <h1 style={{'fontSize': '10vw', 'border': 'none'}}  className={`selectButton `} onClick={nextWord}>{CurrentWord.word} </h1> */}

                      </div>
                      : <button  className="btn btn-warning btn-lg" onClick={initWordsStarted(true)}><span className="option-buttons">Start</span></button>
                    }

                    

                  </div>
                </div>
              </div>
              {/* <p><small>Word ID: {CurrentWord.id} | CurrentWordNum: {CurrentWordNum} | NumerOfWords: {NumerOfWords}</small></p>*/}
            </>}

        </div>
        : <><div className="lds-ring"><div></div><div></div><div></div><div></div></div></>
      }




NEXT;: YOU HAVE TO MAKE THE START BUTTON SHOW FIEST WHEN ITS CLICK, YOU START THE SLKDIES SO TO ALLOW THE SOUND TO PLAY
{ WordItems.length > 0 &&
//https://github.com/leandrowd/react-responsive-carousel/issues/204
  <Carousel 
  //autoPlay={true} 
  infiniteLoop={true} 
  interval={5000} 
  showStatus={true}
  dynamicHeight={true}
  showIndicators={false}
  onSwipeStart={true}

  onChange={handleChange}
  >
  

{WordItems.map((word, index) => {
            return (
              <div key={index}>
            <h1 style={{'fontSize': '10vw', 'border': 'none'}}  className={`selectButton `}>{word.word}</h1>
            </div>
            )
          })}


</Carousel>
}
            
      <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem",
        }}
      >
        <Link to="/">Home</Link>
      </nav>

      <footer>
        <ol>
          {Score.wrong.map((wrongWord, index) => {
            return (<li key={index}>{wrongWord}</li>)
          })}
        </ol>

      </footer>

    </div>
  )

  function handleChange(selectedIndex) {
    // do whatever with index
    console.log(`LINE 275 selectedIndex=`, selectedIndex);
    console.log(`LINE 275 selectedIndex=`, selectedIndex);
    playAudio(WordItems[selectedIndex].word);
    }

}
