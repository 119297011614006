import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import './Home.css';
import "bootstrap/dist/css/bootstrap.min.css";

export default function Home() {
  const [Categories, initCategories] = useState([])
  const [SelectedCategory, setSelectedCategory] = useState()
  const [program, setProgram] = useState()
  const [showSort, setshowSort] = useState('asc')
  const [sortOptions, setsortOptions] = useState([
    'asc',
    'desc',
    'rand',
  ]);
  const [range, setRange] = useState({ min: 0, max: 0 })
  const [formErrors, setFormErrors] = useState([])
  const [showFormErrors, setShowFormErrors] = useState(false)

  const navigate = useNavigate();
  //console.log('LINE 15=', process.env.REACT_APP_API_URL);
  const fetchData = async () => {
    const response = await fetch(`https://www.websor.com/pre-school/words/84words/api/?r=Y2F0ZWdvcmllcw`)
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }
  useEffect(() => {
    fetchData()
      .then((res) => {
        //console.log('LINE 15=', process.env.REACT_APP_API_URL);
        //console.log('LINE 15=', res.length);
        console.log('LINE 15=', res);

        initCategories(res);


        //set the default
        //nextWord()



      })
      .catch((e) => {
        console.log(e.message)
      })
  }, [])
  //https://stackoverflow.com/questions/66834831/how-to-link-to-a-page-with-picked-item-from-dropdown
  function handleChange() {
    //console.log('LINE 38=',newValue.target.value );

    //setSelectedCategory(newValue.target.value)

    //Push method in React Hooks (useState)?
    //https://bobbyhadz.com/blog/react-push-to-state-array
    const erroMsgs = [];
    if (!program) erroMsgs.push('Please Select a Program');
    if (!SelectedCategory) erroMsgs.push('Please Select a Category');
    if (!showSort) erroMsgs.push('Please Select Word Order Sort');
    if (range.min == '' && range.min != 0) erroMsgs.push('Please Select Min Range');
    if (range.max == '' && range.max != 0) erroMsgs.push('Please Select Max Range');

    if (range.min && range.max && range.min != 0 && range.max != 0) {
      if ((range.min + range.max) < 20) {
        erroMsgs.push('You must select atleast 20 questions in your range. Your total in range is: ' + (range.min + range.max));
      }
    }


    if (erroMsgs.length > 0) {
      console.log(`LINE 67 erroMsgs=`, erroMsgs);
      setFormErrors(erroMsgs)
      //alert("Select requirements");
      setShowFormErrors(true);
    } else {
      navigate(`/${program}/${SelectedCategory}/${showSort}/${range.min}-${range.max}`);
    }



  }
  const labels = ["Spell", "Say", "Select", "Drag", "SlideShow"];
  return (
    <div className='homeComponent'>

      {showFormErrors && <>{formErrors.map((error, index) => { return (<div key={index} className="alert alert-danger">{error}</div>) })}</>}

<h1>Practice Sight Words</h1>
<p>The purpose of this app is for kids in Kindergarten or First Grade to practice their sight words homework online for free, no virus, malware, data tracking. Safe for kids! - This app was created for my kids who are in first grade and kinder garden and they practice the sight words everyday as homework from their elementary school.  </p>
<hr />
      <h4>Select Program: {program}</h4>

    

      {/* <pre>{JSON.stringify(formErrors, null, 2)}</pre> */}



      <div onChange={(e) => setProgram(e.target.value)} >
        {labels.map((label, index) => {
          return (

            <div className="form-check form-check-inline" key={index}>
              <input className="form-check-input" type="radio" name="program" id={`inlineRadio${index}`} value={label} />
              <label className="form-check-label" htmlFor={`inlineRadio${index}`}>{label}</label>
            </div>


          )
        })}
      </div>






      <hr />





      <div className="row">
        <div className="col">
          <label htmlFor="exampleFormControlSelect1"><b>Select Category:</b> {SelectedCategory}</label>



          <select className="form-control" id="exampleFormControlSelect1" onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="0">Select Category</option>
            {Categories.map((category, index) => {
              return (
                <option key={index} value={category.catid}>{category.category}</option>
              )
            })}
          </select>
        </div>

        <div className="col">
          <label htmlFor="exampleFormControlSelect1"><b>Select Word Order:</b> {showSort}</label>
          <select className="form-control" id="exampleFormControlSelect1" onChange={(e) => setshowSort(e.target.value)}>
            {sortOptions.map((order, index) => {
              return (
                <option key={index} value={order}>{order}</option>
              )
            })}
          </select>
        </div>

      </div>

      <div className="form-group my-4">






        <p> <b>Select Range:</b> leave 0 for all <small>(range by sort number not by word id)</small> [{range.min} - {range.max}]</p>
        <b>Min</b><input type="text" size="4" value={range.min}
          name="min"
          onChange={(e) => setRange({ ...range, [e.target.name]: e.target.value })} /> - <b>Max</b>
        <input type="text" size="4" value={range.max}
          name="max"
          onChange={(e) => setRange({ ...range, [e.target.name]: e.target.value })}
        />

      </div>





      <button type="button" className="btn btn-primary" onClick={handleChange}>Go</button>

      <footer className='text-center text-light'>ClouDFlare Version 10/10/2023 6:28pm<br />Source: /g/SCHOOL/Apps/2022/84-words-kinder <br /> Source: F:\apachefriends\xampp\htdocs\websor\pre-school\words\84words\api</footer>
    </div>
  )
}
