import React, { useState, useEffect } from 'react'


import { Link, useParams } from "react-router-dom";


import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'


export default function Drag(props) {
  const params = useParams();
  console.log('LINE 9 params=', params.id);


  let CurrentWordNumTemp;
  // THIS ARRAY WILL DETERMINE HOW MANY BUTTONS TO SHOW
  let buttonColors = ['primary', 'warning', 'info', 'danger', 'success', 'secondary', 'light', 'dark']
  let randNumbers = [];

  const [WordItems, initWord] = useState([])
  const [Completed, initCompleted] = useState(false)
  const [NumerOfWords, initNumerOfWords] = useState(0)
  const [CurrentWordNum, initCurrentWordNum] = useState(-1)
  const [CurrentWord, initCurrentWord] = useState({ word: "Start" })
  const [RandWords, initRandWords] = useState([])
  const [ShowWord, initShowWord] = useState(true)
  const [Score, initScore] = useState({ correct: [], wrong: [] })
  const [PexelImageUrl, initPexelImageUrl] = useState()
  const [ShowImage, initShowImage] = useState()
  let playingAudio = false;
  const fetchData = async () => {
    const response = await fetch(`https://www.websor.com/pre-school/words/84words/api/?i=${params.id}&sort=${params.sort}&range=${params.range}`)
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }
  const checkAnswer = (submittedAnswer) => {

    if (CurrentWord.word_id === submittedAnswer) {
      //alert('correct');
      //initScore({correct: Score.correct++})
      playAudio('_answer_correct');
      initShowWord(false);
      setTimeout(() => {
        nextWord();
      }, 2000);


    } else {
      Score.wrong.push(CurrentWord.word);
      //initScore({wrong: Score.wrong++})
      playAudio('_answer_wrong');
      initShowWord(true);
      //alert('wrong current id='+CurrentWord.word_id+' and you submitted '+submittedAnswer+' CurrentWordNumTemp='+CurrentWordNumTemp);
    }
    //console.log('LINE 24 submittedAnswer=', submittedAnswer);

    //alert(submittedAnswer);
  }
  const GetPexelImage = (word) => {
    initPexelImageUrl('');

    fetch("https://api.pexels.com/v1/search?query=" + word + "&local=en-US&per_page=1", {
      headers: {
        Authorization: '563492ad6f91700001000001d3f7b88bcf7f43bcaa1452ff21311ad4'
      }
    })
      .then(resp => {
        return resp.json()
      })
      .then(data => {
        //console.log(data)
        //console.log(data.photos[0].src.tiny);
        initPexelImageUrl(data.photos[0].src.tiny)
        //$('#WordImg').attr('src', data.photos[0].src.tiny);
        //$('#image').attr('src',data.photos[0].src.tiny);
      })

  }
  //https://stackoverflow.com/a/12646864
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  const nextWord = () => {
    initShowWord(true);
    CurrentWordNumTemp = CurrentWordNum;
    CurrentWordNumTemp++;
    initCurrentWordNum(CurrentWordNumTemp)
    if (CurrentWordNum > NumerOfWords) {
      console.log('LINE 19 NumerOfWords=', NumerOfWords);
      console.log('LINE 20 CurrentWordNum=', CurrentWordNum);
      initCompleted(true)
    } else {

      // Generate unique random numbers
      // https://stackoverflow.com/questions/2380019/generate-unique-random-numbers-between-1-and-100
      while (randNumbers.length < (buttonColors.length - 1)) {
        let r = Math.floor(Math.random() * (NumerOfWords - 1)) + 1;
        if (randNumbers.indexOf(r) === -1) randNumbers.push(r);
      }
      // now push the correc answer
      randNumbers.push(CurrentWordNumTemp);
      shuffleArray(randNumbers);

      //console.log('LINE 63 randNumbers=', randNumbers);


      initRandWords(randNumbers);
      initCurrentWord(WordItems[CurrentWordNumTemp]);

      //alert();
      setTimeout(() => {
        playAudio(WordItems[CurrentWordNumTemp].word);
      }, 100);
      if (!WordItems[CurrentWordNumTemp].image) GetPexelImage(WordItems[CurrentWordNumTemp].word);
    }

  }
  const playAudio = (word) => {
    //alert(word)
    // also available: https://ssl.gstatic.com/dictionary/static/sounds/20200429/teacher--_us_1.mp3 from google
    let audio = new Audio(`https://www.websor.com/pre-school/words/84words/sounds/?w=${word}`)
    //https://stackoverflow.com/questions/16077642/javascript-html5-audio-dont-play-sound-if-already-playing
    if (!playingAudio) {
      playingAudio = true;
      setTimeout(() => {
        playingAudio = false;
        audio.play();
      }, 100);
    }

  }



  useEffect(() => {
    fetchData()
      .then((res) => {
        //console.log('LINE 15=', res);
        //console.log('LINE 15=', res.length);

        if (typeof res.error == "undefined") {

          initNumerOfWords(res.length);
          initWord(res);
          initShowWord(true);

          //set the default
          //nextWord()
          //alert(res.error);
        }
        else {
          // alert('error');
          initShowWord(false);
        }





      })
      .catch((e) => {
        console.log(e.message)
      })
  }, [])
  shuffleArray(buttonColors)
  return (
    <div className="container  text-center">



      {ShowWord ?
        <div className="wordContainer">
          {CurrentWordNum === NumerOfWords ?

            <>


              <h1>Results</h1>
              <ol>
                {Score.wrong.map((wrongWord, index) => {
                  return (<li key={index}>{wrongWord}</li>)
                })}
              </ol>
            </>


            :



            <>

              <div className="card mb-4 shadow-sm">
                <div className="card-header">


                  {ShowImage &&
                    <img className='img-fluid wordImage'
                      src={`https://www.websor.com/pre-school/words/84words/assets/img/${CurrentWord.img ? CurrentWord.img : `${CurrentWord.word}.jpg`}`}
                      alt="Drag"
                      title={CurrentWord.word} />
                  }



                </div>
                <div className="card-body">





                  <div className="" >
                    {/* <h1 className="text-center CurrentWord">{CurrentWord.image}</h1> */}






                    {RandWords.length !== 0 ?

                      <div>
                        <div className="RepeatWord" onClick={() => playAudio(CurrentWord.word)}>🔊</div>

                        {RandWords.map((id, index) => {

                          return (

                            // https://stackoverflow.com/questions/43604167/retrieve-which-button-was-clicked-in-react
                            <button key={index} className={`selectButton btn btn-${buttonColors[index]} btn-lg `} onClick={() => checkAnswer(WordItems[id].word_id)} >{WordItems[id].word}</button>

                          )
                        })}

                      </div>
                      : <button className="btn btn-warning btn-lg" onClick={nextWord}><span className="option-buttons">Start</span></button>
                    }

                    <div><input className="form-check-input" type="checkbox" value="1" checked={ShowImage} onChange={(e) => initShowImage(!ShowImage)} /> Show Images</div>

                  </div>
                </div>
              </div>
              {/* <p><small>Word ID: {CurrentWord.id} | CurrentWordNum: {CurrentWordNum} | NumerOfWords: {NumerOfWords}</small></p>*/}
            </>}

        </div>
        : <><div className="lds-ring"><div></div><div></div><div></div><div></div></div></>
      }



      <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem",
        }}
      >
        <Link to="/">Home</Link>
      </nav>

      <footer>
        <ol>
          {Score.wrong.map((wrongWord, index) => {
            return (<li key={index}>{wrongWord}</li>)
          })}
        </ol>

      </footer>

    </div>
  )

}
