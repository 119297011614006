import React, { useState, useEffect } from 'react'
import './Say.css';
import "bootstrap/dist/css/bootstrap.min.css"
import { Link, useParams } from "react-router-dom";
export default function Say(props) {
  const params = useParams();
  //console.log('LINE 9 params=', params.id);
  let catId = params.id;

  const [wordsList, setWordsList] = useState(null)
  const [lastKeyNumber, setlastKeyNumber] = useState(0)
  const [ShowWord, setShowWord] = useState(false)

  const [Score, setScore] = useState({ correct: [], wrong: [], answeredWrong: [] })

  const [CurrentWord, setCurrentWord] = useState(-1)
  const [CurrentWordKey, setCurrentWordKey] = useState(-1)

  const [showResults, setShowResults] = useState(false)
  const [resultsMsg, setResultsMsg] = useState('Results')
  const [wrongList, setWrongList] = useState([])



  const checkAnswer = (submittedAnswer) => {
    if (submittedAnswer === 'wrong') {
      Score.wrong.push(wordsList[CurrentWordKey]);
      if (typeof Score.answeredWrong[wordsList[CurrentWordKey].word] == "undefined") {
        Score.answeredWrong[wordsList[CurrentWordKey].word] = { count: 0, word: '', sort: 0 };
      }
      Score.answeredWrong[wordsList[CurrentWordKey].word].count++;
      Score.answeredWrong[wordsList[CurrentWordKey].word].word = wordsList[CurrentWordKey].word;
      Score.answeredWrong[wordsList[CurrentWordKey].word].sort = wordsList[CurrentWordKey].sort;


    } else {
      Score.correct.push(wordsList[CurrentWordKey]);
    }

    // next question
    console.log(`LINE 32 
    lastKeyNumber=${lastKeyNumber},
    CurrentWordKey=${CurrentWordKey},
    
    `);
    if (lastKeyNumber == CurrentWordKey) {
      if (Score.wrong.length > 0) {
        // Has wrong answered - reload the wrong answer

        setWordsList(Score.wrong);
        setlastKeyNumber(Score.wrong.length - 1);
        setScore({ ...Score, wrong: [] }); //reset wron array
        setCurrentWordKey(0);

        console.log(`LINE 38 wordsList=`, wordsList);
        console.log(`LINE 38 Score.wrong=`, Score.wrong);
        return false
      } else {
        console.log(`LINE 56 Score.answeredWrong=`, Score.answeredWrong);
        let thiswrongList = '';
        let counter=0;
        for (let [word, obj] of Object.entries(Score.answeredWrong)){
          if(counter>0) thiswrongList+=',';
          thiswrongList+=obj.sort;
          
          counter++;
        }
        setWrongList(thiswrongList);

        setShowResults(true);
        setResultsMsg('Finished. See Results. :) ')

      }
      return false
    } else {
      setCurrentWordKey((CurrentWordKey + 1));
    }



  }





  const fetchData = async () => {
    const response = await fetch(`https://www.websor.com/pre-school/words/84words/api/?i=${catId}&sort=${params.sort}&range=${params.range}`)
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }
  useEffect(() => {
    fetchData()
      .then((res) => {
        //console.log('LINE 15=', res);
        //console.log('LINE 15=', res.length);
        if (typeof res.error == "undefined") {
          setlastKeyNumber(res.length - 1);
          setWordsList(res);
          setShowWord(true);
          setCurrentWordKey(0)
        }
        else {
          // alert('error');
          setShowWord(false);
        }
      })
      .catch((e) => {
        console.log(e.message)
      })
  }, [])


  document.addEventListener("keydown", logKey);

function logKey(e) {

  console.log(e.key )
}

  return (

    <div className="row" onKeyDown={()=>logKey}>

      {showResults ?
        <>
          <h1 className='text-center mb-5 alert alert-info'>{resultsMsg}</h1>
          <ul className="list-group w-50 m-auto">

            {Object.entries(Score.answeredWrong).map(([word, wordObj], index) => {
              return (
                <li key={index} class="list-group-item d-flex justify-content-between align-items-center">
                  <b className='fs-2'>{word} </b>
                  <span>{wordObj.sort}</span>
                  <span className="badge bg-danger rounded-pill">{wordObj.count}</span>
                </li>)

            }
            )}
          </ul>
          <div className='text-center m-3'>
            <a href={`/Say/${catId}/${params.sort}/${wrongList}-0`}>Try Again {wrongList}</a> |  <a href={`/Say/${catId}/${params.sort}/${params.range}`}>Try {params.range} Again</a> |  <Link to="/" className=''>Home</Link>
          </div>
        </>


        :
        <>
          {wordsList &&
            <div className="wordContainer">
              <h1 className='sayWord text-center'>{wordsList[CurrentWordKey].word}</h1>

              <div className="wordContainerOptions">
                <div className="m-3">
                  <Link to="/" className='btn btn-success  mr-2'>🏠</Link>

                  <button className={`btn btn-light `} onClick={() => checkAnswer('correct')} >✔️</button>
                  <button className={`btn btn-light `} onClick={() => checkAnswer('wrong')} >❌</button>

                  <button className='btn btn-primary ml-2'>{CurrentWordKey + 1}</button>
                  <button className='btn btn-secondary  ml-2'># {wordsList[CurrentWordKey].sort}</button>

                  {CurrentWordKey > 0 && <button className={`btn btn-light  ml-2`} onClick={() => setCurrentWordKey((CurrentWordKey - 1))} >🡄</button>}
                  {lastKeyNumber > CurrentWordKey && <button className={`btn btn-light  ml-2`} onClick={() => setCurrentWordKey((CurrentWordKey + 1))} >🡆</button>}
                </div>
                <div className="m-3" >



                </div>
              </div>
            </div>
          }</>
      }

    </div>


  )
}
