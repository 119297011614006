import React, { useState, useEffect } from 'react'
import './Say.css';
import "bootstrap/dist/css/bootstrap.min.css"
import { Link, useParams } from "react-router-dom";
export default function Spell(props) {
    //console.log(`LINE 7 ############################################ `);
    const params = useParams();
    //console.log('LINE 9 params=', params.id);
    let CurrentWordIndexTemp;
    let buttonColors = ['primary', 'warning', 'info', 'danger']
    let randNumbers = [];
    const [WordItems, initWordItems] = useState([])
    const [Completed, initCompleted] = useState(false)
    const [NumerOfWords, initNumerOfWords] = useState(0)
    const [CurrentWordIndex, initCurrentWordIndex] = useState(-1)
    const [CurrentWord, initCurrentWord] = useState({ word: "Start" })
    const [RandWords, initRandWords] = useState([])
    const [ShowWord, initShowWord] = useState(true)
    const [Score, initScore] = useState({ correct: [], wrong: [] })
    const [PexelImageUrl, initPexelImageUrl] = useState()
    const [submittedAnswer, initsubmittedAnswer] = useState('')
    const [RevealCurrentWord, initRevealCurrentWord] = useState(false)
    let playingAudio = false;
    const SubmitAnswer = (e) => {
        e.preventDefault();
        if (submittedAnswer == '') return false; // return if empty
        if (CurrentWord.word === submittedAnswer) {
            //alert('correct');
            //initScore({correct: Score.correct++})
            playAudio('_answer_correct');
            //initShowWord(false);
            setTimeout(() => {
                nextWord();
            }, 2000);
        } else {
            Score.wrong.push(CurrentWord.word);
            //initScore({wrong: Score.wrong++})
            playAudio('_answer_wrong');
            //initShowWord(true);
            // alert('wrong current word='+CurrentWord.word+' and you submitted '+submittedAnswer+' CurrentWordIndexTemp='+CurrentWordIndexTemp);
        }
        //console.log('LINE 24 submittedAnswer=', submittedAnswer);
        //alert(submittedAnswer);
    }
    const GetPexelImage = (word) => {
        initPexelImageUrl('');
        fetch("https://api.pexels.com/v1/search?query=" + word + "&local=en-US&per_page=1", {
            headers: {
                Authorization: '563492ad6f91700001000001d3f7b88bcf7f43bcaa1452ff21311ad4'
            }
        })
            .then(resp => {
                return resp.json()
            })
            .then(data => {
                //console.log(data)
                //console.log(data.photos[0].src.tiny);
                if (typeof data.photos[0] != "undefined") initPexelImageUrl(data.photos[0].src.tiny)

                //$('#WordImg').attr('src', data.photos[0].src.tiny);
                //$('#image').attr('src',data.photos[0].src.tiny);
            })
    }
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const nextWord = () => {
        initRevealCurrentWord(false);
        initShowWord(true);
        initsubmittedAnswer('');
        CurrentWordIndexTemp = CurrentWordIndex;
        CurrentWordIndexTemp++;
        // console.log('LINE 19 NumerOfWords=', NumerOfWords);
        // console.log('LINE 20 CurrentWordIndex=', CurrentWordIndex);
        // console.log('LINE 71 WordItems=', WordItems);
        initCurrentWordIndex(CurrentWordIndexTemp)

        if (CurrentWordIndex === NumerOfWords) {
            // console.log(`LINE 74  CurrentWordIndex is > NumerOfWords` );
            initCompleted(true)
        } else {
            // console.log(`LINE 77 ELSE CurrentWordIndex is < NumerOfWords` );
            // Generate unique random numbers
            // https://stackoverflow.com/questions/2380019/generate-unique-random-numbers-between-1-and-100
            while (randNumbers.length < 2) {
                let r = Math.floor(Math.random() * (NumerOfWords - 1)) + 1;
                if (randNumbers.indexOf(r) === -1) randNumbers.push(r);
            }
            // now push the correc answer
            randNumbers.push(CurrentWordIndexTemp);
            shuffle(randNumbers);
            // console.log('LINE 63 randNumbers=', randNumbers);
            initRandWords(randNumbers);
            if (typeof WordItems[CurrentWordIndexTemp] == "undefined") {
                initCompleted(true)
                return false;
            }
            initCurrentWord(WordItems[CurrentWordIndexTemp]);
            //alert();
            setTimeout(() => {
                playAudio(WordItems[CurrentWordIndexTemp].description?WordItems[CurrentWordIndexTemp].description:WordItems[CurrentWordIndexTemp].word);
            }, 100);
            if (!WordItems[CurrentWordIndexTemp].image) GetPexelImage(WordItems[CurrentWordIndexTemp].word);
        }
    }
    const playAudio = (word) => {
        //alert(word)
        // also available: https://ssl.gstatic.com/dictionary/static/sounds/20200429/teacher--_us_1.mp3 from google
        let audio = new Audio(`https://www.websor.com/pre-school/words/84words/sounds/?w=${word}`)
        //https://stackoverflow.com/questions/16077642/javascript-html5-audio-dont-play-sound-if-already-playing
        if (!playingAudio) {
            playingAudio = true;
            setTimeout(() => {
                playingAudio = false;
                audio.play();
            }, 100);
        }
    }
    //https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
    const shuffle = (array) => {
        let currentIndex = array.length, randomIndex;
        // While there remain elements to shuffle.
        while (currentIndex !== 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }
    const RevealWord = () => {
        initRevealCurrentWord(true);
        setTimeout(() => {
            initRevealCurrentWord(false)
        }, 1000);
        Score.wrong.push(CurrentWord.word);
    }
    const fetchData = async () => {
        const response = await fetch(`https://www.websor.com/pre-school/words/84words/api/?i=${params.id}&sort=${params.sort}&range=${params.range}`)
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    }
    useEffect(() => {
        fetchData()
            .then((res) => {
                console.log('LINE 14=', res[9]);
                //console.log('LINE 15b=', res.length);
                if (typeof res.error == "undefined") {
                    initNumerOfWords(res.length);
                    // minus one for the category object in res
                    //https://stackoverflow.com/a/19544496  Remove last item from array
                    let popped = res.pop();
                    if (params.sort == "rand") res = shuffleArray(res);

                    // console.log(`LINE 145  reslen =`, res);
                   





                    initWordItems(res); // .pop()  
                    initShowWord(true);
                    //set the default
                    //nextWord()
                    //alert(res.error);
                }
                else {
                    // alert('error');
                    initShowWord(false);
                }
            })
            .catch((e) => {
                // console.log(e.message)
            })
    }, [])

    console.log('LINE 15 WordItems= ', WordItems[CurrentWordIndex]);
    return (
        <div className="container  text-center">
            {ShowWord ?
                <div className="wordContainer">
                    {CurrentWordIndex === NumerOfWords ?
                        <>
                            <h1>Results</h1>
                            <ol>
                                {Score.wrong.map((wrongWord, index) => {
                                    return (<li key={index}>{wrongWord}</li>)
                                })}
                            </ol>
                        </>
                        :
                        <>
                            <div className="card mb-4 shadow-sm">
                                <div className="card-header">


                                    {CurrentWord.description &&<div className="alert alert-info m-5 vocabulary">{CurrentWord.description}</div>}

                                    {!CurrentWord.description &&
                                        <img className='img-fluid wordImage' src={
                                            CurrentWord.image ?
                                                `https://www.websor.com/pre-school/words/84words/assets/img/${CurrentWord.image}`
                                                : PexelImageUrl} alt="Spell Word" title="No cheating is allowed!" />
                                    }



                                </div>
                                <div className="card-body">
                                    <div className="" >
                                        {RandWords.length !== 0 ?
                                            <form onSubmit={SubmitAnswer}>
                                                <table style={{ width: "100%" }}><tbody>
                                                    <tr>
                                                        <td colSpan={2} style={{ fontSize: "40px" }}>                                        {RevealCurrentWord &&
                                                            RevealCurrentWord && CurrentWord.word
                                                        }</td>
                                                    </tr>
                                                    <tr>
                                                        <td><input spellcheck="false" style={{ fontSize: "30px", fontWeight: "bold" }} className="form-control  form-control-lg" type="text" value={submittedAnswer} placeholder='Spell Word' onChange={(e) => initsubmittedAnswer(e.target.value)} /></td>
                                                        <td><button className="btn btn-primary" type='submit' style={{ backgroundColor: "#0d6efd" }}>Go</button></td>
                                                    </tr>
                                                </tbody></table>

                                                <div className="RepeatWord" ><span onClick={() => playAudio(CurrentWord.description ? CurrentWord.description : CurrentWord.word)}>🔊</span> {CurrentWordIndex + 1} of {NumerOfWords}</div>
                                                {!CurrentWord.description && <div className="RevealWord" onClick={() => RevealWord(CurrentWord.word)}>👀</div>}

                                            </form>
                                            : <button className="btn btn-warning btn-lg" onClick={nextWord}><span className="option-buttons">Start</span></button>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <p><small>Word ID: {CurrentWord.id} | CurrentWordIndex: {CurrentWordIndex} | NumerOfWords: {NumerOfWords}</small></p>*/}
                        </>}
                </div>
                : <><div className="lds-ring"><div></div><div></div><div></div><div></div></div></>
            }
            <nav
                style={{
                    borderBottom: "solid 1px",
                    paddingBottom: "1rem",
                }}
            >
                <Link to="/">Home</Link>
            </nav>
            <footer>
                {CurrentWordIndex === NumerOfWords &&
                    <ol>
                        <li>Wrong Answers</li>
                        {Score.wrong.map((wrongWord, index) => {
                            return (<li key={index}>🥺 {wrongWord}</li>)
                        })}
                    </ol>
                }
            </footer>
        </div>
    )
}
