import React from 'react';
import {   BrowserRouter,
  Routes,
  Route} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Spell from './components/Spell';
import Say from './components/Say';
import Select from './components/Select';
import Drag from './components/Drag';
import SlideShow from './components/SlideShow';
import Knight from './components/dnd/Knight';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/Spell/:id/:sort/:range" element={<Spell />} />
      <Route path="/Say/:id/:sort/:range" element={<Say />} />
      <Route path="/Select/:id/:sort/:range" element={<Select />} />
      <Route path="/Drag/:id/:sort/:range" element={<Drag />} />
      <Route path="/SlideShow/:id/:sort/:range" element={<SlideShow />} />
      <Route path="/dnd" element={<Knight />} />
      <Route path="/" element={<App />} />
    </Routes>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
